import { enableIndexedDbPersistence, initializeFirestore } from "firebase/firestore";
import env from 'env/env.beta';

const firestore = (...params: Parameters<typeof initializeFirestore>) => {
  const firestore = initializeFirestore(...params);
  if ("window" in globalThis) {
    enableIndexedDbPersistence(firestore)
    .catch((err) => {
      if (err.code == 'failed-precondition') {
          console.log('Multiple tabs open, persistence can only be enabled in one tab at a a time.');
        } else if (err.code == 'unimplemented') {
        console.log('The current browser does not support all of the features required to enable persistence.');
      }
    });
  }
  return firestore;
}

export default env({
  firestore
});
