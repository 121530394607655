export default (firebaseOptions = {}) => ({
  configuration: 'beta',
  baseUrl: 'https://beta.kampoy.com',
  production: true,
  firebaseConfig: {
    options: {
      apiKey: 'AIzaSyBWxXsgFDtzTPXZQZkquiUHZw5qvGlj4oU',
      authDomain: 'beta-kampoy.firebaseapp.com',
      projectId: 'beta-kampoy',
      storageBucket: 'beta-kampoy.appspot.com',
      messagingSenderId: '755266708593',
      appId: '1:755266708593:web:9c61fb42bb673e02aca7d0',
      measurementId: 'G-PCM6RFZDH7',
    },
    ...firebaseOptions,
    vapidKey: 'BIUz7Mao2pio-OwrOjByiA9plwFi24MYylj-3VUsRsIz_nr5t7IBVF1IKQG7Db2S2-ImSZaZMYUhKPJOx9C8mv4'
  },
  googleMap: 'AIzaSyCjiwBfIrS6cHqLZUhe8bZkAfcPis8YbCM',
  contact: 'contact@kampoy.com',
  stripe: {
    publicKey: 'pk_test_51JZgzNDVNkawKAx6kavbH2ZFEja8EsG62qhW6FPSliAulJZQWbjnKUetoUzg9OYKR5yyc4Cn0DmSSffmspMt2ewO00OlVbHMaO',
    livemode: false
  },
  useEmulators: false,
  sendgrid: {
    email: 'contact@kampoy.com',
    sandbox: false,
    templateIds: {
      newSeller: 'd-6d1d6fa2b132498384e86450816168d7',
      receipt: 'd-a9d11755ab764dcba8ef5d90cf335509',
      invoice: 'd-99ef9b25c7b54fc7904173c62298a33a',
      monthlyKampoyExpense: 'd-e7c15789bc1549d8bead19fec99a9b07',
      deliveryDeleted: 'd-ec56976280cf453ebb2f40f4402d0e94',
      feeUpdate: 'd-927931956ba8477686e3ffddaca73b89',
      invoiceUpdate: 'd-c0d1fc7ea3b84a00a746f2156b72d7a7',
      invoiceDelete: 'd-46fa2f17700e4c099919c3ffa32f34ce',
    },
  },
  twilio: {
    from: '+33757901202',
    sandbox: false,
  },
})
